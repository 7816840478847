import React, {Component} from 'react';
import Isotope from 'isotope-layout/js/isotope';
import ImagesLoaded from 'imagesloaded/imagesloaded';
import YouTube from 'react-youtube-embed'

class ItemGrid extends Component {

    state = {
        activeItem: '*',
    }

    componentDidMount() {
        var imgLoad = new ImagesLoaded('.grid');
    
        imgLoad.on('progress', function(instance, image) {
            this.iso = new Isotope('.grid', {
                itemSelector: '.grid-item',
                layoutMode: "masonry"
            });
        }); 
        
    }
    onFilterChange = (newFilter) => {
        
        this.setState({activeItem: newFilter});
        if (this.iso === undefined) {
            this.iso = new Isotope('.grid', {
            itemSelector: '.grid-item',
            layoutMode: "masonry"
            });
        }
    
    // this.iso.arrange({ filter: newFilter });
        
      if(newFilter === '*') {
        this.iso.arrange({ filter: `*` });
      } else {
        this.iso.arrange({ filter: `.${newFilter}` });
      }
    }

    onActive = v => v === this.state.activeItem ? 'active' : '';
    render() {
        return(
            <div>


            <div className="grid row">
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item">
                    <div className="portfolio hover-style" style={{  boxShadow: "0px 30px 60px 0px rgba(0, 11, 40, 0.5)"}}>
                        <YouTube id='nwyEyRrnf9Q' />
                        <div className="item-img-overlay">
                        </div>						
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item">
                    <div className="portfolio hover-style" style={{  boxShadow: "0px 30px 60px 0px rgba(0, 11, 40, 0.5)"}}>
                        <YouTube id='jRRKe4ANk1c' />
                        <div className="item-img-overlay">
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12 grid-item">
                    <div className="portfolio hover-style" style={{  boxShadow: "0px 30px 60px 0px rgba(0, 11, 40, 0.5)"}}>
                        <YouTube id='1gjSqZK1wEk' />
                        <div className="item-img-overlay">
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item">
                    <div className="portfolio hover-style" style={{  boxShadow: "0px 30px 60px 0px rgba(0, 11, 40, 0.5)"}}>
                        <YouTube id='jMGpZRNeCRo' />
                        <div className="item-img-overlay">
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item">
                    <div className="portfolio hover-style" style={{  boxShadow: "0px 30px 60px 0px rgba(0, 11, 40, 0.5)"}}>
                        <YouTube id='dqiEUWh05uM' />
                        <div className="item-img-overlay">
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12 grid-item">
                    <div className="portfolio hover-style" style={{  boxShadow: "0px 30px 60px 0px rgba(0, 11, 40, 0.5)"}}>
                        <YouTube id='KYS_I3KBhQk' />
                        <div className="item-img-overlay">
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
  }

  export default ItemGrid;
