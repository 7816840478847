import React, {Component} from 'react';
import RBanner from './component/Banner/RippleBanner';
import About from './component/About';
import Service from './component/Service';
import Portfolio from './component/Portfolio/Portfolio';
import Contact from './component/Contact';
import Skill from './component/Skill/Skill';
import jhonData from './component/jhonData';
import Navs from './navs';


class Home5 extends Component{

    render(){
        return(
  <div className="body_wrapper" >
    <Navs/>
    <RBanner jhonData={jhonData} bClass="banner_shap" imag="man.jpg" textLeft="text-left"/>
    <About aClass="about_area" jhonData={jhonData}/>
    <Skill/>
    <Portfolio/>
    <Service wClass="work_area" jhonData={jhonData}/>
    <Contact jhonData={jhonData}/>
  </div>

        );
    }
}

export default Home5