import React, {Component} from 'react';
import Slider from 'react-slick';

 class TestimonialSlider extends Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
          fade: true,
          lazyLoad: true,
          arrows: true,
        speed: 500,
        autoplay: true,
        pauseOnHover:true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
      return (
        <div>

          <Slider {...settings} className="testimonial_slider">
                <div className="item">
                    <div className="author_img">
                        <img src={require('../../image/onur-tahmaz.jpg')} alt="Onur Tahmaz"/>
                    </div>
                    <h6>Onur Tahmaz</h6>
                    <span>Sanat Yönetmeni</span>
                </div>
                <div className="item">
                    <div className="author_img">
                        <img src={require('../../image/duygu-esenkar.jpg')} alt="Duygu Esenkar"/>
                    </div>
                    <h6>Duygu Esenkar</h6>
                    <span>Genel Müdür & Orkestra Müdürü</span>
                </div>
                <div className="item">
                    <div className="author_img">
                        <img src={require('../../image/yunus-mercan.jpg')} alt="Yunus Mercan"/>
                    </div>
                    <h6>Yunus Mercan</h6>
                    <span>Dijital Stratejiler & Pazarlama Direktörü</span>
                </div>
              <div className="item">
                  <div className="author_img">
                      <img src={require('../../image/babursah-tugrul.jpg')} alt="Babürşah Tuğrul"/>
                  </div>
                  <h6>Babürşah Tuğrul</h6>
                  <span>Görüntü Yönetmeni</span>
              </div>
          </Slider>
        </div>
      );
    }
  }
  export default TestimonialSlider;