import React, {Component} from 'react';
import Sticky from 'react-stickynode';
import {Link} from 'react-scroll';
import Logo from "../../logo";
class Navbar extends Component{


    render(){

        var {mClass, mContainer} = this.props;
        return (

            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">

                <nav className={`navbar navbar-expand-lg navbar-light ${mClass}`}>
                    <div className=".header-wrapper">

                        <div className={`container ${mContainer}`}>


                            <a className="navbar-brand logo_h" href="/">
                                <Logo className="lottie"/>
                            </a>

                            <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                                <ul className="nav navbar-nav m-auto">
                                    <li className="nav-item">
                                        <Link className="nav-link" activeClass="active" to="home" spy={true} smooth={true} duration={500}>
                                            Home
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" activeClass="active" to="about" spy={true} smooth={true} duration={500}>
                                            About
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" activeClass="active" to="skill" spy={true} smooth={true} duration={500}>
                                            Our Team
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" activeClass="active" to="portfolio" spy={true} smooth={true} duration={500}>
                                            Watch & Listen
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" activeClass="active" to="service" spy={true} smooth={true} duration={500}>
                                            Sponsors
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" activeClass="active" to="contact" spy={true} smooth={true} duration={500}>
                                            Contact
                                        </Link>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </nav>
            </Sticky>


        );
    }
}

export default Navbar;