import React from "react";
import { useForm } from '@formcarry/react';


function MyFormcarry() {
    // Call the `useForm` hook in your function component
    const {state, submit} = useForm({
        id: 'MTNmYmT8498n'
    });

    // Success message
    if (state.submitted) {
        return <div>
            <h2 className="title">Thank you for contacting us!</h2>
            <p className="description">Our professionals will get back to you ASAP!</p>
        </div>;
    }


    return(
        <form onSubmit={submit}>

            <div className="row">
                <div className="col-lg-6">
                    <label htmlFor="name">Name*</label>
                    <input style={{background:"rgba(172, 150, 107, 0.49)"}} id="name" type="text" name="text" className="form-control"/>
                </div>
                <div className="col-lg-6">
                    <label htmlFor="email">Your e-mail address*</label>
                    <input style={{background:"rgba(172, 150, 107, 0.49)"}} className="form-control" id="email" type="email" name="email" />
                </div>
                <div className="col-lg-6">
                    <label htmlFor="subject">What is it about?*</label>
                    <input style={{background:"rgba(172, 150, 107, 0.49)"}} type="text" id="subject" name="subject" className="form-control"/>
                </div>
                <div className="col-lg-6">
                    <label htmlFor="phone">Phone*</label>
                    <input style={{background:"rgba(172, 150, 107, 0.49)"}} type="text" className="form-control" id="phone" name="phone"/>
                </div>
            </div>
            <label htmlFor="message">Tell us more ...</label>
            <textarea style={{background:"rgba(172, 150, 107, 0.49)"}} name="message" id="message" className="form-control" rows="6"/>
            <button type="submit" className="btn send_btn theme_btn">Send Your Message</button>
        </form>
    );
}
export default MyFormcarry;