import React from 'react';
import Logo from "./logo";

export const NotFound = () => (

    <div className="body_wrapper">
        <section className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image" style={{
            backgroundImage: `url(${require('./image/bogazici-filarmoni-orkestrasi.mp4')}), url(${require('./image/bogazici-filarmoni-orkestrasi-3.jpg')})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        }} data-black-overlay="5">
            <div className="container">
                <div className="error_text">


                    <a href="/"> <Logo/></a>
                    <div className="banner_content" style={{textAlign:"center", top:"50px"}}>
                        <h1 style={{fontSize:"100px"}}>404</h1>
                    <a href="/"><h1 style={{fontSize:"50px"}}>Return to Home Page</h1></a>
                    </div>
                </div>
            </div>
        </section>
    </div>
);