import React, {Component} from 'react';
import Sectiontitle from '../component/Banner/Sectiontitle';
import Fade from 'react-reveal/Fade';

class Service extends Component{
    render(){
        let jhonData = this.props.jhonData;
        var {wClass} = this.props
        return(
            <section className={`slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image ${wClass}`} id="service" style={{
                backgroundImage: `url(${require('../image/bogazici-filarmoni-orkestrasi-5.jpg')})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }} data-black-overlay="5">
                <div className="container" >
                    <Sectiontitle Title="Sponsorlar" TitleP="Boğaziçi Filarmoni, başarısını hiçbir zaman desteklerini esirgemeyen kurumsal şirketler, vakıflar ve kamu kuruluşlarına borçlu. Destekçilerimizden bazıları:"/>
                    <Fade bottom cascade duration={1000}>
                        <div className="row">
                            {
                                jhonData.service && jhonData.service.map(item =>{
                                return(
                                        <div className={item.lg} key={item.id}>
                                            <div className="work_item wow fadeInUp" data-wow-delay="0.6s" style={{borderRadius:"7px"}}>
                                                <a href={item.link} target="_blank" rel="noopener noreferrer" className="c_items"><img className="sponsors" style={{width:"150px", height:"150px"}} src={item.iconName} alt={item.alt}/></a>
                                            </div>
                                        </div>
                                        )
                                    }
                                )
                            }
                        </div>
                    </Fade>
                </div>
            </section>
        )
    }
}

export default Service;