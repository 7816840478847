import React, {Component} from 'react';
import {Link} from "react-scroll/modules";
import {Fade} from 'react-reveal/';

class About extends Component {
    render(){
        let jhonData = this.props.jhonData; 
        var {aClass} = this.props;
        return(
            <section className={`ptb--120 slide slide-style-1 slider-fixed--height d-flex align-items-center ${aClass}`} id="about" style={{
                backgroundImage: `url(${require('../image/people.jpg')})`,
                backgroundRepeat: "repeat",
            }} data-black-overlay="6">
                <div className="container " >
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex align-items-center">
                            <Fade bottom cascade duration={1000}>
                                <div className="about_content">
                                    <h2 className="t_color" style={{color:"#f6e4b7"}}>{jhonData.aboutme}</h2>
                                    <p style={{textAlign:"justify", marginTop:"2em"}}>{jhonData.aboutdetails}</p>
                                    <p style={{textAlign:"justify", marginTop:"1em"}}>{jhonData.aboutdetails2}</p>
                                    <a href="https://medium.com/locksbridge/bosphorus-philharmonic-53cca2303324?source=friends_link&sk=ca5d7da488485756fd91e002bd56ec17"
                                       target="_blank" rel="noopener noreferrer"
                                       className="theme_btn active">Medium'da Okuyun</a>
                                    <Link className="theme_btn" to="contact"  activeClass="active" spy={true} smooth={true} duration={300}>Seçmelere Başvurun</Link>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default About;
