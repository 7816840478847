
const jhonData = {
    "name": "Bosphorus Philharmonic Orchestra",
    "socialLinks":[
        {
          "name": "linkedin",
          "url": "https://www.linkedin.com/company/bosphilharmonic/",
          "className": "social_linkedin"
        },
        {
          "name":"instagram",
          "url":"https://www.instagram.com/bosphilharmonic/",
          "className":"social_instagram"
        },
        {
            "name":"facebook",
            "url":"https://www.facebook.com/bosphilharmonic/",
            "className":"social_facebook"
          }
      ],
      "aboutme": "About Us",
      "aboutdetails": "Bosphorus Philharmonic Orchestra is a youth orchestra consisting of university students and supported by professionals. The Orchestra, with its wide age spectrum from 11 to 50, is the result of the members’ collective work and devotion that each member has for music. \n" +
          "Music students as well as students from Turkey’s best universities, future doctors, engineers, sociologists, economists.",
    "aboutdetails2": "Bosphorus Philharmonic Orchestra, rehearsed by professional musicians, is one of the most remarkable social initiatives of the recent years. The Orchestra performed with the world-renowned Pianist and Composer Hüseyin Sermet playing Beethoven’s Piano Concerto №4. At the latest concert, Bosphorus Philharmonic has performed the Turkish Premiere of Respighi’s Concerto in modo misolidio with Pianist Tutu Aydınoğlu as the soloist at the one of the most prestigious concert halls of Turkey, Istanbul Lütfi Kırdar ICEC, with an audience of 1700 people . With Onur Tahmaz, Conductor and Artistic Director, Bosphorus Philharmonic continues to curate the most energetic and unique projects.",
      "service":[
          {
              "id" : 1,
              "iconName": [require('../image/so-chic.png')],
              "serviceTitle": "So Chic",
              "lg": "col-lg-6 col-md-6 col-sm-6 col-xs-6",
              "link": "https://www.sochic.com.tr/",
              "alt": "So Chic"
          },
          {
            "id" : 2,
            "iconName": [require('../image/eti.png')],
            "serviceTitle": "Eti",
              "lg": "col-lg-6 col-md-6 col-sm-6 col-xs-6",
              "link": "https://www.etietieti.com/",
              "alt": "Eti"
          },
          {
              "id" : 3,
              "iconName": [require('../image/caffe-nero.png')],
              "serviceTitle": "Caffe Nero",
              "alt": "Caffe Nero",
              "link": "https://caffenero.com/tr/",
              "lg": "col-lg-4 col-md-4 col-sm-4 col-xs-4"
          },
          {
              "id" : 4,
              "iconName": [require('../image/lutfi-kirdar.png')],
              "serviceTitle": "Istanbul Lütfi Kırdar ICEC",
              "alt": "Istanbul Lütfi Kırdar ICEC",
              "link": "https://www.icec.org/",
              "lg": "col-lg-4 col-md-4 col-sm-4 col-xs-4"
          },
          {
            "id" : 5,
            "iconName": [require('../image/basarim-sensin-dernegi.png')],
            "serviceTitle": "Başarım Sensin Derneği",
              "alt": "Başarım Sensin Derneği",
              "link": "http://www.basarimsensin.org/",
              "lg": "col-lg-4 col-md-4 col-sm-4 col-xs-4"
          }
      ],
      "contact":[
        {
          "id": "1",
          "title": "Management",
          menuItems: [
              {
              id: 1,
              icon: 'icon_mail',
              text: 'LocksBridge represents Bosphorus Philharmonic for worldwide general management.',
            },
              {
                  id: 2,
                  icon: 'icon_globe',
                  text: 'Website: https://locksbridge.net/',
              }
          ]
        },
      ],
    contacttitle: 'Contact Us',
}

export default jhonData;

