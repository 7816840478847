
const jhonData = {
    "name": "Boğaziçi Filarmoni Orkestrası",
    "socialLinks":[
        {
          "name": "linkedin",
          "url": "https://www.linkedin.com/company/bosphilharmonic/",
          "className": "social_linkedin"
        },
        {
          "name":"instagram",
          "url":"https://www.instagram.com/bosphilharmonic/",
          "className":"social_instagram"
        },
        {
            "name":"facebook",
            "url":"https://www.facebook.com/bosphilharmonic/",
            "className":"social_facebook"
          }
      ],
      "aboutme": "Biz Kimiz?",
      "aboutdetails": "Türkiye gençliğinin bir minyatürü. Boğaziçi Filarmoni Orkestrası öğrencilerden oluşan profesyoneller tarafından da desteklenen bir gençlik orkestrasıdır. 11 yaşından 50 yaşına kadar geniş bir yaş spektruma sahip bu orkestra, her bir üyesinin sanata duydukları derin aşk ile kollektif bir çalışma örneği. Konservatuvar öğrencilerinin yanı sıra Türkiye’nin en iyi üniversitelerinde öğretim gören ve enstrümanlarında hatrı sayılır bir noktaya gelmiş doktorlar, mühendisler, sosyologlar, ekonomistler ve başka birçok alanda uzmanlaşan gençlerin de yoğun çabası ve emeği ile diğer orkestralardan sahip olduğu misyon ve vizyon gereği farklı bir konuma sahip.",
    "aboutdetails2": "Sanat kurulunun yoğun ve ciddi mülakatlarından geçen orkestra üyeleri; bugünün genç, yarının yıldız yeteneklerini bünyesinde bulundururken; iş dünyasında geleceğin fark yaratacak insanlarından oluşuyor. Sanata erişimin ve sanatla temasın toplumun her bireyi için ulaşılabilir kılmayı görev edinmiş Boğaziçi Filarmoni Orkestrası; gençler için sağladığı bu platform ile bir toplum hizmeti. Nota siparişinden, ışık ve ses sistemine kadar her aşamaya dahil olan geleceğin iş insanları bu platform ile sanatın yoğun emek gerektiren işleyişini tecrübe ederek iç görü kazanıyorlar. Profesyoneller tarafından çalıştırılan Boğaziçi Filarmoni Orkestrası, son yılların dikkat çeken sosyal girişimlerinden. Dünyaca ünlü piyanist Hüseyin Sermet ile Beethoven’ın 3 numaralı piyano konçertosunu seslendiren orkestra, en son konserinde; piyanist Doç. Tutu Aydınoğlu solistliğinde Respighi’nin miksolidik moddaki piyano konçertosunun Türkiye Prömiyeri’ni, Türkiye’nin en prestijli konser salonlarından biri olan Lütfi Kırdar Kongre ve Sergi Sarayı’nda 1700 kişilik bir dinleyici kitlesi önünde gerçekleştirmiştir. Orkestra şefliğini ve sanat yönetmenliğini Onur Tahmaz’ın yürütttüğü Boğaziçi Filarmoni Orkestrası bitmek bilmeyen enerjisi ve çalışma aşkı ile Türkiye’nin en özgün projelerini gerçekleştirmeye devam ediyor.",
      "service":[
          {
              "id" : 1,
              "iconName": [require('../image/so-chic.png')],
              "serviceTitle": "So Chic",
              "lg": "col-lg-6 col-md-6 col-sm-6 col-xs-6",
              "link": "https://www.sochic.com.tr/",
              "alt": "So Chic"
          },
          {
            "id" : 2,
            "iconName": [require('../image/eti.png')],
            "serviceTitle": "Eti",
              "lg": "col-lg-6 col-md-6 col-sm-6 col-xs-6",
              "link": "https://www.etietieti.com/",
              "alt": "Eti"
          },
          {
              "id" : 3,
              "iconName": [require('../image/caffe-nero.png')],
              "serviceTitle": "Caffe Nero",
              "alt": "Caffe Nero",
              "link": "https://caffenero.com/tr/",
              "lg": "col-lg-4 col-md-4 col-sm-4 col-xs-4"
          },
          {
              "id" : 4,
              "iconName": [require('../image/lutfi-kirdar.png')],
              "serviceTitle": "Istanbul Lütfi Kırdar ICEC",
              "alt": "Istanbul Lütfi Kırdar ICEC",
              "link": "https://www.icec.org/",
              "lg": "col-lg-4 col-md-4 col-sm-4 col-xs-4"
          },
          {
            "id" : 5,
            "iconName": [require('../image/basarim-sensin-dernegi.png')],
            "serviceTitle": "Başarım Sensin Derneği",
              "alt": "Başarım Sensin Derneği",
              "link": "http://www.basarimsensin.org/",
              "lg": "col-lg-4 col-md-4 col-sm-4 col-xs-4"
          }
      ],
      "contact":[
        {
          "id": "1",
          "title": "Yönetim",
          menuItems: [
              {
              id: 1,
              icon: 'icon_mail',
              text: 'LocksBridge, Boğaziçi Filarmoni Orkestrası\'nı dünya genelinde temsil ediyor.',
            },
              {
                  id: 2,
                  icon: 'icon_globe',
                  text: 'Website: https://locksbridge.net/',
              }
          ]
        },
      ],
    contacttitle: 'Bize Yazın',
}

export default jhonData;

