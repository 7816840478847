import React , {Component} from 'react';
import anime from 'animejs/lib/anime.es.js';
import CounterItem from '../Skill/CounterItem';
import Reveal from 'react-reveal/Reveal/';
import TestimonialSlider from "../Testimonial/TestimonialSlider";


class Skill extends Component {
    componentDidMount(){
        function countup(el, target) {
            let data = { count: 0 };
            anime({
              targets: data,
              count: [0, target],
              duration: 2000,
              round: 1,
              delay: 200,
              easing: 'easeOutCubic',
              update() {
                el.innerText = data.count.toLocaleString();
              } });
          
          }
          
          function makeCountup(el) {
            const text = el.textContent;
            const target = parseInt(text, 10);
          
            const io = new IntersectionObserver(entries => {
              entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                  countup(el, target);
                  io.unobserve(entry.target);
                }
              });
            });
          
            io.observe(el);
          }
          
          const els = document.querySelectorAll('[data-countup]');
          
        els.forEach(makeCountup);
    }
    render(){
        return(
            <section id="skill">
                <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image" style={{
                    backgroundImage: `url(${require('../../image/bogazici-filarmoni-orkestrasi-6.jpg')})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }} data-black-overlay="6">

                    <div className="container">
                        <Reveal effect="fadeInDown">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12 testimonial_area">
                                    <TestimonialSlider/>
                                </div>
                            </div>
                        </Reveal>
                        <Reveal effect="fadeInUp">
                        <div className="row">
                            <div className="col-lg-12 col-md-12col-sm-12 col-xs-12 project_count_area">
                                <div className="row">
                                    <CounterItem CText='5' pdescription='Years Old This Year' icon='speedometer'/>
                                    <CounterItem CText='20' pdescription='Concerts and Counting' icon='map-pin'/>
                                    <CounterItem CText='100' pdescription='Musicians' icon='trophy'/>
                                    <CounterItem CText='10000' pdescription='People Attended Our Concerts' icon='profile-female'/>
                                </div>
                            </div>
                        </div>




                    </Reveal>

                    </div>

                </div>

            </section>
        )
    }
}
export default Skill;