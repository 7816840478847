import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Home5 from './Home5';
import Home5tr from './Home5tr';
import {NotFound} from './404';



class App extends Component{

  render(){
    return(
        <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Home5}/>
            <Route exact path="/tr" component={Home5tr}/>
            <Route component={NotFound} />

          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
export default App;
