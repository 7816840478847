import React, {Component} from 'react';
import ItemGrid from './ItemGrid';
import Sectiontitle from '../../component/Banner/Sectiontitle';

class portfolio extends Component {
    render() {
      var {pClass}= this.props;
      return(
        <section className={`portfolio_area slide slide-style-1 slider-fixed--height d-flex align-items-center ${pClass}`} id="portfolio" style={{
            backgroundImage: `url(${require('../../image/cats.jpg')})`,
            backgroundRepeat: 'repeat',
        }} data-black-overlay="5">
            <div className="container" >
                <Sectiontitle Title="İzleyin & Dinleyin" TitleP="Youtube kanalımıza abone olmayı unutmayın!"/>
                <ItemGrid/>
            </div>
        </section>
      )
    }
  }

  export default portfolio;
