import React, { useState, useContext } from 'react'
import {stack as Menu} from 'react-burger-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-scroll/modules";
import Logo from '../logo';
import style from './style.js'
import { NavLink } from 'react-router-dom';

var styles = {
    bmCross: {
        display: "none"
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100vh',
    },
    bmMenu: {
        background: '#f5f5f5',
        padding: '2.5em 1.5em 0',
        font:"600 1.2em 'Raleway', sans-serif",
        color: "#151515"
    },
    bmItemList: {
        color: '#000',
        padding: '0.8em'
    },
    bmItem: {
        display: 'block',
        lineHeight: "3em",
        cursor: "pointer",
        transition: "all 0.5s ease",
        active:{
            backgroundColor: "#666",
            color: "white",
        },
        hover:{
            backgroundColor: "#666",
            color: "white",
        }

    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.5)',
    }
};

// make a new context
const MyContext = React.createContext();

// create the provider
const MyProvider = (props) => {
    const [menuOpenState, setMenuOpenState] = useState(false);

    return (
        <MyContext.Provider value={{

            isMenuOpen: menuOpenState,
            toggleMenu: () => setMenuOpenState(!menuOpenState),
            stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
            closeMenu () {
                this.setState({menuOpen: false})
            }

        }}>
            {props.children}
        </MyContext.Provider>
    )
};

// create a button that calls a context function to set a new open state when clicked
const Button = () => {
    const ctx = useContext(MyContext);
    const [hover, setHover] = useState(false);
    return (
        <FontAwesomeIcon className="hamburger_icon" style={{position:"fixed",zIndex: "10010", top: "1.5em", right:"1em", fontSize: "30px", color:"#AC966B",
            ...(hover ? style.hover : null)
        }} onClick={ctx.toggleMenu} icon={faBars}
                         onMouseEnter={()=>{
                             setHover(true);
                         }}
                         onMouseLeave={()=>{
                             setHover(false);
                         }}
        />
    )
};

// create a navigation component that wraps the burger menu
const Navigation = () => {
    const ctx = useContext(MyContext);

    return (
        <Menu
            customBurgerIcon={false}
            isOpen={ctx.isMenuOpen}
            onStateChange={(state) => ctx.stateChangeHandler(state)}
            styles={ styles }
            right

        >
                <a className="hamburger_mobile"  key="0">
                    <Link onClick={ctx.toggleMenu} to="home"  activeClass="active" spy={true} smooth={true} duration={300}>
                        Ana Sayfa
                    </Link>
                </a>
                <a className="hamburger_mobile" key="1">
                    <Link onClick={ctx.toggleMenu}   to="about" activeClass="active" spy={true} smooth={true} duration={300}>
                        Hakkımızda
                    </Link>
                </a>
                <a className="hamburger_mobile" key="2" >
                    <Link onClick={ctx.toggleMenu}   to="skill"  activeClass="active" spy={true} smooth={true} duration={300} >
                        Ekibimiz
                    </Link>
                </a>
                <a className="hamburger_mobile" key="3" >
                    <Link onClick={ctx.toggleMenu}  to="portfolio" activeClass="active" spy={true} smooth={true} duration={300}>
                        İzleyin & Dinleyin
                    </Link>
                </a>
                <a className="hamburger_mobile" key="4" >
                    <Link  onClick={ctx.toggleMenu} to="service" activeClass="active" spy={true} smooth={true} duration={300} >
                        Sponsorlar
                    </Link>
                </a>
                <a className="hamburger_mobile" key="5" >
                    <Link onClick={ctx.toggleMenu}  to="contact" activeClass="active" spy={true} smooth={true} duration={300}>
                        İletişim
                    </Link>
                </a>
            <a style={{marginRight:"15px", display:"inline-block",marginTop:"50px",}}><NavLink to="/" activeStyle={{filter:"brightness(0.5)"}}><img style={{width: "20px", textAlign: "center"}} src={require('../image/us.png')} alt="English"/></NavLink></a>
            <a style={{marginLeft:"0px", display:"inline-block"}}><NavLink to="/tr"><img style={{width: "20px", textAlign: "center", filter:"brightness(1.2)"}} src={require('../image/turkey.png')} alt="Türkçe"/></NavLink></a>
        </Menu>
    )
};

// default export here
const Nav = () => {

    return (
        <MyProvider>
            <div>
                <a className="navbar-brand logo_h" href="/"  style={{position:"fixed",zIndex: "10010", top: "1em", left:"1em",
                    webkitTransition: "all 0.5s ease",
                    oTransition: "all 0.5s ease",
                    transition: "all 0.5s ease",
                }}>
                    <Logo/>
                </a>
                <Button />
                <Navigation />
            </div>
        </MyProvider>
    )
};

export default Nav;