import React, {Component} from 'react';

class CounterItem extends Component{
    render(){
        let {col, CText, pdescription,icon} = this.props;
        return(
            <div className={`col-md-6 col-sm-6 ${col}`}>
                 <div className="counter_item text-center wow fadeInUp" data-wow-delay="0.1s">
                    <i className={`icon-${icon}`} style={{color:"#f5f5f5", fontWeight: "900", fontSize: "50px"}}></i>
                    <h3 className="t_color" style={{color: "#f5f5f5"}} data-countup>{CText}</h3>
                    <p style={{color:"#d5d5d5", fontWeight: "300", letterSpacing: "0", fontSize: "20px"}} className="t_color">{pdescription}</p>
                </div>
            </div>
        )
    }
}
export default CounterItem;