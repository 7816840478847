import Nav from './component/hamburger';
import Navbar from './component/Navbar/Navbar';
import React, { Component } from 'react';

class navs extends Component {
    constructor(props) {
        super(props)
        this.state = { matches: window.matchMedia("(min-width: 800px)").matches };
    }

    componentDidMount() {
        const handler = e => this.setState({matches: e.matches});
        window.matchMedia("(min-width: 800px)").addListener(handler);
    }
    render() {
        return (
            <div >
                {this.state.matches && (<Navbar/>)}
                {!this.state.matches && (<Nav/>)}
            </div>
        );
    }
}

export default navs;