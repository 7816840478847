import React, {Component} from 'react';
import RBanner from './componenttr/Banner/RippleBanner';
import About from './componenttr/About';
import Service from './componenttr/Service';
import Portfolio from './componenttr/Portfolio/Portfolio';
import Contact from './componenttr/Contact';
import Skill from './componenttr/Skill/Skill';
import jhonData from './componenttr/jhonData';
import Navs from './navstr';



class Home5tr extends Component{

    render(){

        return(
<div className="body_wrapper" >
    <Navs/>
    <RBanner jhonData={jhonData} bClass="banner_shap" imag="man.jpg" textLeft="text-left"/>
    <About aClass="about_area" jhonData={jhonData}/>
    <Skill/>
    <Portfolio/>
    <Service wClass="work_area" jhonData={jhonData}/>
    <Contact jhonData={jhonData}/>

  </div>
        );
    }
}
export default Home5tr