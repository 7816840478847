import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal/';
import {Link} from "react-scroll/modules";
import Fade from 'react-reveal/Fade';
import Logo2 from "../logo2";
import Form from './form'
import { NavLink } from 'react-router-dom';



class Contact extends Component {


    render(){
        let jhonData = this.props.jhonData;
        return(
            <section className="contact-area slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image" id="contact" style={{
                backgroundImage: `url(${require('../image/bogazici-filarmoni-orkestrasi-2.mp4')}), url(${require('../image/bogazici-filarmoni-orkestrasi-2.jpg')})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }} data-black-overlay="7">
                <div className="container" >
                    <div className="row row-reverse">
                        {
                            jhonData.contact && jhonData.contact.map(item =>{
                            return(
                                    <React.Fragment key={item.id}>
                                        <div className="col-md-5">
                                            <Reveal effect="fadeInLeft" duration={500}>
                                                <div className="contact_info">
                                                    <h4>{item.title}</h4>
                                                    <ul className="nav">
                                                        {
                                                            item.menuItems.map(info =>{
                                                                return(
                                                                    <li className="item" key={info.id}>
                                                                        <div className="media">
                                                                                <i className={info.icon}/>
                                                                            <div className="media-body">
                                                                                <a href="https://locksbridge.net/en/artist/bosphilharmonic/" target="_blank" rel="noopener noreferrer">{info.text}</a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </Reveal>
                                        </div>
                                    </React.Fragment>
                                    )
                                }
                            )
                        }
                        <div className="col-md-7">
                            <Reveal effect="fadeInRight" duration={800}>
                                <div className="input_form">
                                    <h4>{jhonData.contacttitle}</h4>
                                    <Form/>
                                </div>
                            </Reveal>
                        </div>
                    </div>
                            <div className="footer-area footer-content" style={{bottom: "0"}}>
                                <Link to="home"  activeClass="active" spy={true} smooth={true} duration={300} className="logo wow fadeInDown" data-wow-delay="0.4s">
                                    <Logo2/>
                                </Link>
                                <Fade top cascade>
                                    <ul className="list_style">
                                        {
                                            jhonData.socialLinks.map(item =>{
                                                return(
                                                    <li key={item.name}>
                                                        <a  href={item.url} target="_blank" rel="noopener noreferrer"><i  className={item.className}/></a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </Fade>
                                <a style={{marginRight:"25px", display:"inline-block",marginTop:"15px",}}><NavLink to="/" activeStyle={{filter:"brightness(1.2)"}}><img style={{width: "20px", textAlign: "center"}} src={require('../image/us.png')} alt="English"/></NavLink></a>
                                <a style={{marginLeft:"0px", display:"inline-block"}}><NavLink to="/tr"><img style={{width: "20px", textAlign: "center",filter:"brightness(0.5)"}} src={require('../image/turkey.png')} alt="Türkçe"/></NavLink></a>
                                <h6>designed by <a href="https://locksbridge.net/" target="_blank" rel="noopener noreferrer">LocksBridge</a></h6>
                                <p>Copyright © 2020 Bosphorus Philharmonic. All Rights Reserved.</p>
                            </div>
                </div>
            </section>
        )
    }
}
export default Contact;