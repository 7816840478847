import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from './alone.json'

class Logo2 extends Component {


    render(){

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return(
            <div>
                <Lottie options={defaultOptions}
                        speed={0.3}
                        width={100}/>
            </div>
        )
    }
}

export default Logo2