import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal/';
import {NavLink} from "react-router-dom";




class Banner extends Component {
    render(){
        let jhonData = this.props.jhonData;
        let { textLeft}= this.props;
        return(
            <section id="home" >
                 <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image" style={{
                     backgroundImage: `url(${require('../../image/bogazici-filarmoni-orkestrasi.mp4')}), url(${require('../../image/bogazici-filarmoni-orkestrasi-3.jpg')})`,
                     backgroundPosition: 'center',
                     backgroundSize: 'cover',
                     backgroundRepeat: 'no-repeat',
                 }} data-black-overlay="5">


                             <div className="container">
                                <div className={`banner_content ${textLeft}`}>
                                    <Reveal  effect="fadeInUp" duration={1500}>
                                        <h1 className="wow fadeInLeft animated">{jhonData.name}</h1>
                                    </Reveal>
                                    <Reveal effect="fadeInUp" duration={2200}>
                                        <h4 className="wow fadeInUp animated">Determination and discipline come to life; <br/><span style={{fontWeight:"400"}}>Hard-work and devotion reflect to every note;</span> <br/>
                                            <span>Bosphorus Philharmonic Orchestra is a sample of the young.</span></h4>
                                    </Reveal>
                                    <ul className="list_style social_icon">
                                    {
                                        jhonData.socialLinks && jhonData.socialLinks.map(item =>{
                                        return(
                                                <li key={item.name}>
                                                <a  href={item.url} target="_blank" rel="noopener noreferrer"><i className={item.className}/></a>
                                                </li>
                                                )
                                            }
                                        )
                                    }
                                    </ul>
                                    <a style={{marginRight:"25px", display:"inline-block",marginTop:"50px",}}><NavLink to="/" activeStyle={{filter:"brightness(1.2)"}}><img style={{width: "20px", textAlign: "center"}} src={require('../../image/us.png')} alt="English"/></NavLink></a>
                                    <a style={{marginLeft:"0px", display:"inline-block"}}><NavLink to="/tr"><img style={{width: "20px", textAlign: "center", filter:"brightness(0.5)"}} src={require('../../image/turkey.png')} alt="Türkçe"/></NavLink></a>
                                </div>
                            </div>
                </div>
            </section>
        );
    }
}

export default Banner;